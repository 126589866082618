<!-- eslint-disable -->
<template>
  <div class="page_box" v-loading="pageLoading">
    <sb-headline title="保养模版"></sb-headline>
    <el-form ref="form" :model="formData" :rules="formRules" label-width="150px">
      <div class="t_module">
        <div class="t_module_title">基础信息</div>
        <el-divider></el-divider>
        <div class="t_module_basics">
          <el-form-item class="t_form_item" label-width="80px" label="标题：" prop="templateName">
            <el-input v-model="formData.templateName" placeholder="请输入标题"></el-input>
          </el-form-item>
          <el-form-item class="t_form_item" label-width="80px" label="背景图：" prop="backgroundUrl">
            <file-upload
              v-model="formData.backgroundUrl"
              :uploadBusinessInfo="{
                businessType: '0'
              }"
              :defaultFileList="bgUrlList"
              :maxNum="1"
              width="72px"
              height="72px"
              @uploadSuccess="uploadSuccess()"
            ></file-upload>
          </el-form-item>
        </div>
      </div>
      <div class="t_module">
        <div class="t_module_title">保养售卖信息</div>
        <el-divider></el-divider>
        <div>
          <el-table :data="formData.services" border style="width: 100%" size="mini"
          :header-cell-style="{
            borderColor: '#EBEEF5',
            background: '#FAFAFA',
            color: '#333',
            fontWeight: 'normal'
          }">
            <el-table-column prop="date" label="品牌" width="180">
              <template slot-scope="scope">
                <el-form-item label-width="0" :prop="`services.${scope.$index}.brandCode`" :rules="formRules.brandCode">
                  <el-select v-model="scope.row.brandCode" filterable remote reserve-keyword placeholder="请选择品牌" @change="(e)=>brandChange(e,scope.$index)" :remote-method="getBrandList" :loading="selLoading">
                    <el-option v-for="item in brandList" :key="item.brandCode" :label="item.brandName" :value="item.brandCode"> </el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="date" label="品牌图标" width="120">
              <template slot-scope="scope">
                <el-image v-if="scope.row.brandLogo" style="width: 60px; height: 60px" :src="scope.row.brandLogo" fit="fit"></el-image>
                <div v-else>—</div>
              </template>
            </el-table-column>
            <el-table-column prop="date" label="服务项目套餐" width="350">
              <template slot-scope="scope">
                <el-form-item label-width="0" :prop="`services.${scope.$index}.serviceCode`" :rules="formRules.serviceCode">
                  <el-select v-model="scope.row.serviceCode" filterable remote reserve-keyword placeholder="请选择套餐" @change="serviceChange" :remote-method="getPackageList" :loading="selLoading" style="width: 100%;">
                    <el-option v-for="item in packageList" :key="item.serviceCode" :label="item.serviceName" :value="item.serviceCode"> </el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="date" label="介绍标签" min-width="400">
              <template slot-scope="scope">
                <el-form-item label-width="0" :prop="`services.${scope.$index}.description`" :rules="formRules.description">
                  <el-tag
                    :key="tag+scope.$index+index"
                    v-for="(tag,index) in services[scope.$index].dynamicTags"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag,scope.$index)">
                    {{tag}}
                  </el-tag>
                  <el-input
                    class="input-new-tag"
                    v-if="services[scope.$index].inputVisible"
                    v-model="services[scope.$index].inputValue"
                    :ref="'saveTagInput-'+scope.$index"
                    size="small"
                    @keyup.enter.native="handleInputConfirm(scope.$index)"
                    @blur="handleInputConfirm(scope.$index)"
                  >
                  </el-input>
                  <el-button v-else class="button-new-tag" size="small" @click="showInput(scope.$index)">添加标签</el-button>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="date" label="排序" width="80">
              <template slot-scope="scope">
                <el-form-item label-width="0" :prop="`services.${scope.$index}.sort`" :rules="formRules.sort">
                  <el-input v-model="scope.row.sort" placeholder="请输入序号"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="date" label="操作" width="100" fixed="right">
              <template slot-scope="scope">
                <el-button type="text" @click="tableDeleteFn(scope.row, scope.$index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-button plain icon="el-icon-plus" size="mini" style="margin-top: 20px;color: #2278f5" @click="addProjectFn">添加项目</el-button>
          <el-button type="primary" size="mini" style="margin-top: 20px;" @click="saveTemplateFn">模版保存</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
/* eslint-disable */
import FileUpload from "@/components/scope/file-upload.vue";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { FileUpload },
    data() {
      return {
        formData: {
          templateCode: '',             //模版code
          templateName: '',             //模版名称
          backgroundUrl: '',            //背景图
          services: [],                 //服务项列表
        },
        formRules: {
          templateName: [{ required: true, message: '请输入标题', trigger: 'blur' }],
          backgroundUrl: [{ required: true, message: '请上传背景图', trigger: 'change' }],
          brandCode: [{ required: true, message: '请选择品牌', trigger: 'change' }],
          serviceCode: [{ required: true, message: '请选择套餐', trigger: 'change' }],
          description: [{ required: true, message: '请添加标签', trigger: 'blur' }],
          sort: [{ required: true, message: '请输入序号', trigger: 'blur' }],
        },
        bgUrlList: [],    //背景图反显用
        brandList: [],    //品牌列表
        selLoading: false,      //搜索loading
        packageList: [],  //套餐列表

        services: [],
        pageLoading: false
      }
    },
  filters: {
    stateFilter: function(val) {
      
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 添加项目
    addProjectFn(){
      this.formData.services.push({});
      this.services.push(
        {
          dynamicTags: [],
          inputVisible: false,
          inputValue: '',
        }
      )
    },
    // 表格内删除
    tableDeleteFn(row, index){
      this.$confirm('是否删除此品牌?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.formData.services.splice(index,1);
        this.services.splice(index,1);
        setTimeout(() => {
          this.saveTemplateFn(); 
        });
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

    // 品牌选择change
    brandChange(e,index){
      this.brandList.forEach(item => {
        if(item.brandCode == e){
          this.formData.services[index].brandLogo = item.brandLogo
        }
      })
      // this.$forceUpdate();
    },
    // 套餐选择change
    serviceChange(e){
      console.log(e)
      // this.$forceUpdate();
    },

    // 模版保存
    saveTemplateFn() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.pageLoading = true;
          this.$store.dispatch('management/postMaintainTemplateSave',{
            ...this.formData,
          }).then(res => {
            if(res.code == 0){
              this.pageLoading = false;
              // 获取模版详情
              this.getDetailInfo()
              this.$message({
                type: 'success',
                message: '保存成功!'
              });
            }
          }).catch(()=>{
            this.pageLoading = false;
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    // 图片上传成功
    uploadSuccess(e){
      this.$refs.form.clearValidate()
    },

    handleClose(tag, index) {
      let services = JSON.parse(JSON.stringify(this.services));
      services[index].dynamicTags.splice(services[index].dynamicTags.indexOf(tag), 1);
      this.formData.services[index].description = services[index].dynamicTags.join();
      this.services = services;
    },

    showInput(index) {
      let services = JSON.parse(JSON.stringify(this.services));
      services[index].inputVisible = true;
      this.services = services;
      this.$nextTick(_ => {
        this.$refs['saveTagInput-'+index].$refs.input.focus();
      });
    },

    handleInputConfirm(index) {
      let inputValue = this.services[index].inputValue;
      if (inputValue) {
        this.services[index].dynamicTags.push(inputValue);
        this.formData.services[index].description = this.services[index].dynamicTags.join();
      }
      this.services[index].inputVisible = false;
      this.services[index].inputValue = '';
      this.$refs.form.clearValidate();
    },

    // 获取模版详情
    getDetailInfo(){
      this.$store.dispatch('management/postMaintainTemplateInfo',{}).then(res => {
        if(res.code == 0){
          console.log(res)
          res.data.services.forEach((item, index) => {
            this.services[index] = {
              dynamicTags: item.description.split(','),
              inputVisible: false,
              inputValue: '',
            }
          })
          Object.keys(this.formData).forEach(key => {
            this.formData[key] = res.data[key]
          })
          this.bgUrlList = [{url: res.data.backgroundUrl}]
        }
      })
    },

    // 获取套餐列表
    getPackageList(e) {
      this.selLoading = true;
      this.$store.dispatch('service-item/getServiceItemList',{
        searchKey: e,
        serviceType: 2,
        pageNum: 1,
        pageSize: 99,
      }).then(res => {
        if(res.code == 0){
          this.packageList = res.data.list;
        }
        this.selLoading = false;
      }).catch(()=>{
        this.selLoading = false;
      })
    },

    // 获取品牌列表
    getBrandList(e) {
      this.selLoading = true;
      this.$store.dispatch('management/postMaintainBrandList',{
        brandName: e,
        brandType: 2,
        pageNum: 1,
        pageSize: 99,
      }).then(res => {
        if(res.code == 0){
          this.brandList = res.data.list;
        }
        this.selLoading = false;
      }).catch(()=>{
        this.selLoading = false;
      })
    },

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // 获取模版详情
    this.getDetailInfo()
    // 获取品牌列表
    this.getBrandList()
    // 获取套餐列表
    this.getPackageList()
  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
  //@import url(); 引入公共css类
  .page_box {
    padding: 0 20px;
    .t_module{
      background: #fff;
      margin-top: 20px;
      padding: 20px;
      box-sizing: border-box;
      .el-divider{
        margin: 15px 0 25px 0;
      }
      .t_module_title{
        font-weight: bold;
        font-size: 16px;
      }
      .t_module_basics{
        display: flex;
        .t_form_item{
          width: 40%;
          margin-bottom: 0 !important;
          .el-input{
            width: 80%;
          }
        }
      }
    }
  }
  
  ::v-deep .el-table__header thead tr th:first-of-type, .el-table__body tbody tr td:first-of-type{
    padding: 0 !important;
  }
  ::v-deep .el-table__body tbody tr td:first-of-type {
    padding-left: 0px !important;
  }

  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
</style>
